import {Component, Vue} from 'vue-property-decorator';
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi, globalConfig} from "../../plugins/axios";
import {Flag} from "@/shared/model/Flag";
import {rules} from "@/shared/model/Rules";
import {BudgetPool} from "@/shared/model/BudgetPool";

@Component({
    components: {},
})
export default class SurveyTypeForm extends Vue {
    title = "SurveyType Form"
    maskText = "";
    valid = true;
    surveyType: SurveyType = new SurveyType();
    statuses = Status.values;
    flags = Flag.values;
    budgetPools = BudgetPool.values.sort();
    requiredRule = rules;

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    submitNewSurveyType(): void {
        evalApi.post("/surveyType/create", {
            surveyTypeId: this.surveyType.surveyTypeId,
            surveyTypeDesc: this.surveyType.surveyTypeDesc,
            surveyType: this.surveyType.surveyType,
            surveyStatus: this.surveyType.surveyStatus,
            commonProjectFlag: this.surveyType.commonProjectFlag,
            expReportFlag: this.surveyType.expReportFlag,
            budgetPool: this.surveyType.budgetPool,
            nfIcfmrFlag: this.surveyType.nfIcfmrFlag,
            surveyClassification: this.surveyType.surveyClassification,
        }, globalConfig).then(res => {
            //window.location.reload();
            alert(res.data.message + " " + res.data.detail)
        }).catch(error => {
            console.log(`An error occurred submitting the surveyType>>>> ${error}`)
        })
    }

    lengthRules = [
        //(v:any) => !!v || 'Required',
        (v:any) =>
            (v && v.length <= 100) ||
            "Must be less than 100 characters"
    ]

}